import {createRouter, createWebHistory} from 'vue-router'
// 获取模块目录上下文
const modulesFiles = require.context('./components', true, /router\.js$/)
const routes = []
modulesFiles.keys().forEach(modulePath => {
    routes.push({
        path: modulesFiles(modulePath).default.path,
        component: modulesFiles(modulePath).default.component
    })
})

const router = createRouter({
    history: createWebHistory(),
    routes
})

let isFirstNavigation = false;

router.beforeEach((to, from, next) => {
    if (routes.filter(item => item.path.includes(to.path)).length === 0) {
        next('/pageNotFound')
    }
    // // 判断是否是刷新操作
    // if (!isFirstNavigation && from.path === '/') {
    //     // console.log('页面是刷新操作');
    //     // 如果是刷新操作，则将路由重定向到主页
    //     next('/')
    // } else {
    //     // 如果不是刷新操作，则正常导航
    //     next();
    // }
    // isFirstNavigation = true;
    next();
});

export default router;
