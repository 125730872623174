import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import router from './router'
import { basicSetup } from 'codemirror'
import VueCodemirror from 'vue-codemirror'
const app = createApp(App);

// 处理全局报错
app.config.errorHandler = (err, vm, info) => {
    // 在这里处理错误
    message.error('异常错误：'+ err+ info);
};


app.use(Antd);
app.use(router);
app.use(VueCodemirror, {
    // optional default global options
    autofocus: true,
    disabled: false,
    indentWithTab: true,
    tabSize: 2,
    placeholder: 'Code goes here...',
    extensions: [basicSetup]
    // ...
})

app.mount('#app');
