<template>
  <!--  <a-watermark v-bind="watermarkModel">-->
  <a-config-provider
      :theme="theme"
  >
  </a-config-provider>
  <a-layout style="min-height: 100vh">
    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" collapsible  :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }">
        <div class="logo" @click="goHome">
          <router-link to="/">
            <img src="@/assets/logo.jpg" style="width: 64px;height: 32px" alt="">
          </router-link>
        </div>
        <a-menu theme="dark" mode="inline"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                class="menu"
        >
          <!--横向：horizontal-->
          <!--有openKeys时 :defaultOpenKeys="[200]"无效 -->
          <!--有v-model时 :ddefaultSelectedKeys="[202]"无效 -->
          <a-sub-menu v-for="itParent in NavData" :key="itParent.Path">
            <template #title>
                  <span>
                    <a-icon :type="itParent.Icons"/>
                    <span>{{ itParent.Title }}</span>
                </span>
            </template>
            <a-menu-item v-for="itChild in itParent.Child" :key="itChild.Path"
                         @click="selectMenuItem(itParent,itChild)">
              <router-link :to="itChild.Path"><!--根据路径去跳转页面-->
                {{ itChild.Title }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout style="height: 98vh; margin-left: 200px">
        <div class="flxHead" ref="flxHead" id="flxHead">
          <a-breadcrumb style="margin: 16px 16px;width: 50%;">
            <a-breadcrumb-item v-for="menuPath in menuPathList" :key="menuPath">{{ menuPath }}</a-breadcrumb-item>
          </a-breadcrumb>
          <div id="hitokoto">
            <a-tooltip color="cyan">
              <template #title>点击搜索语句详情</template>
              <span id="hitokoto_text" @click="getTextInfo()">{{ hitokotoText }}</span>
            </a-tooltip>
          </div>
          <div id="switchContiner" >
            <label class="switch">
              <input type="checkbox" @click="changeChecked">
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
          <div :style="{ padding: '15px', height: '100%' }">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
  <!--  </a-watermark>-->
</template>

<script>
import {ref} from 'vue';
import axios from "axios";
import menuConfig from '/menuConfig.js'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons-vue';
import { theme } from 'ant-design-vue';

const { darkAlgorithm, defaultAlgorithm } = theme;
const collapsed = ref(false);

export default {
  name: 'IndexPage',
  components: {CheckOutlined,CloseOutlined},
  props: {
    msg: String
  },
  data() {
    return {
      collapsed,
      menuPathList: ["首页"],
      NavData: menuConfig,
      timeWatermark: '',
      userName: 'Jiew',
      watermarkModel: {},
      openKeys: [],
      selectedKeys: [],
      lowestChildItems: [],
      hitokotoText: '',
      checked:false,
      theme:{
        algorithm: defaultAlgorithm,
        token: {
          'colorPrimary': '#ff179b',
        }
      }
    }
  },
  methods: {
    selectMenuItem(itParent, itChild) {
      this.menuPathList[1] = itParent.Title
      this.menuPathList[2] = itChild.Title
    },
    goHome() {
      this.$router.go(0);
    },
    findMenuItemByPath(path, menuItems) {
      let result = null;

      function findItem(item) {
        if (item.Path === path) {
          result = item;
          return true;
        }
        if (item.Child) {
          for (const child of item.Child) {
            if (findItem(child)) {
              result = item;
              return true;
            }
          }
        }
        return false;
      }

      // 递归寻找该元素的父元素
      for (let item of menuItems) {
        if (findItem(item)) {
          return result;
        }
      }
      return result;
    },
    getTextInfo() {
      window.open("https://m.gushici.net/chaxun/shici/" + this.hitokotoText)
    },
    changeChecked(){
      this.checked = !this.checked
      console.log(this.checked)
      this.theme.algorithm = this.checked ? darkAlgorithm : defaultAlgorithm
      const lightFlxHead = 'linear-gradient(to right, #ef475d, #5dbe8a, #f1ca17)';
      const nightFlxHead = 'linear-gradient(to right, #bf374a, #4a9970, #c1a20f)';
      this.$refs.flxHead.style.background = this.checked ? nightFlxHead : lightFlxHead;
    }
  },
  watch: {
    // 监控路由设置菜单是否展开选中
    $route(to, from) {
      if (to.path !== '/' && to.path !== '/pageNotFound' && from.path === '/') {
        console.log(to.path)
        let result = this.findMenuItemByPath(to.path, this.NavData);
        console.log(result)
        // 匹配标题头
        this.menuPathList[1] = result.Title
        const childs = result.Child
        childs.forEach(item => {
          if (item.Path === to.path) {
            this.menuPathList[2] = item.Title
          }
        })
        // 设置导航选中展开
        this.selectedKeys = []
        this.selectedKeys.push(to.path)
      }
    }
  },
  // 获取水印内容
  getwatermark() {
    let date = new Date();
    var year = date.getFullYear().toString(); // 获取年份并转换为字符串类型
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（注意月份从0开始计数）并补零到两位
    var day = date.getDate().toString().padStart(2, '0'); // 获取天数并补零到两位
    this.timeWatermark = year + '年' + month + '月' + day + '日';
    this.watermarkModel = {
      content: [this.timeWatermark, this.userName],
      font: {
        fontSize: 16,
      }
    }
  },
  created() {
    this.getwatermark
  },
  mounted() {
    axios.get('https://v1.hitokoto.cn?c=i')
        .then(({data}) => {
          this.hitokotoText = data.hitokoto
        })
        .catch(console.error)
  }
}


</script>
<style scoped>
.logo {
  text-align: center;
  height: 32px;
  margin: 16px;
  background: rgba(0, 21, 41, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

#hitokoto {
  text-align: right;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 16px
}

#switchContiner{
  display: flex;
  align-items: center;
  margin-right: 16px
}

/* From www.lingdaima.com */
/* The switch - the box around the slider */
.switch {
  /* --moon-mask: ; */
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.3em;
  height: 1.5em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f5;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  border-radius: 20px;
  left: 0.3em;
  bottom: 0.3em;
  background: linear-gradient(40deg,#ff0080,#ff8c00 70%);
;
  transition: .4s;
}

input:checked + .slider {
  background-color: #303136;
}

input:checked + .slider:before {
  transform: translateX(1.5em);
  background: #303136;
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -5px 0 0 #a3dafb;
}


#hitokoto_text {
  font-size: 16px;
}

.flxHead {
  color: white;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to right, #E71D36, #2EC4B6, #FF9F1C, #540D6E)
}
</style>
