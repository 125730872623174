<template>
  <IndexPage/>
</template>

<script>
import IndexPage from './components/indexPage'
export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>

</style>
