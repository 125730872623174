const menuConfig = [
    {
        NavID: 100,
        Icons: "dashboard",
        Title: "新医保工具",
        Path: "/",
        Child: [
            {
                NavID: 101,
                Icons: "",
                Title: "RID生成",
                Path: "/birRidUtil",
            },
            {
                NavID: 102,
                Icons: "",
                Title: "飞书发送sql",
                Path: "/fsSendSql",
            },
            {
                NavID: 103,
                Icons: "",
                Title: "驼峰转下划线，下划线转驼峰",
                Path: "/camelUnderscore",
            },
            {
                NavID: 104,
                Icons: "",
                Title: "拆分excel",
                Path: "/splitExcelFile",
            },
            {
                NavID: 105,
                Icons: "",
                Title: "解析MybatisXml",
                Path: "/extractMybatisXml",
            },
            {
                NavID: 106,
                Icons: "",
                Title: "回答问题",
                Path: "/chat",
            },
            {
                NavID: 107,
                Icons: "",
                Title: "MD编辑器",
                Path: "/mdEditor",
            }]
    },
    {
        NavID: 200,
        Icons: "dashboard",
        Title: "速算练习",
        Path: "/quickCalculation",
        Child: [
            {
                NavID: 201,
                Icons: "",
                Title: "除法练习",
                Path: "/quickDivision",
            }]
    },
    {
        NavID: 900,
        Icons: "dashboard",
        Title: "测试菜单",
        Path: "/testParent",
        Child: [
            {
                NavID: 901,
                Icons: "",
                Title: "测试子菜单1",
                Path: "/testParent1",
            }]
    }
]
export default menuConfig
